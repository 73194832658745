.logo-style{
  margin: auto;
  text-align: center;

  
}
.img-styles{
  width:300px;height:75px;
}
.container{
  text-align: center;
  margin: auto;
  width: 80%;
  padding-left: 30px;
  padding: 10px;
 
  border: 10px solid maroon;
  border-radius: 20px;
}

.label-styles{
      width:10%;
      box-sizing: border-box;
      text-align: left;
}
.dropdownlist{
  width: 100%;
}

.form-style{
  width: 100%;
}

.logo-style{
  padding: 30px;
}

.input-styles{
  background: none;
  background-color: white;

  font-size: 15px;
  box-sizing : border-box;
  display: block;
  width: 100%;

  margin: 25px 0;
  margin-right: 25px;
  text-align: left;

}

.email-style{
  background: none;
  background-color: white;
  color: black;
  font-size: 14px;
  box-sizing : border-box;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid grey;

  margin-right: 25px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.dropdown-style{
  background: none;
  background-color: white;
  color: black;
  font-size: 15px;
  box-sizing : border-box;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid grey;
  font-family: 'Philosopher', sans-serif;

  margin-right: 25px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.submit-button {
  font-size: 20px;
  background-color: maroon;
  color: white;
  border-radius: 10px;
  padding: 5px
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
